import React  from 'react';
import { Link } from "react-router-dom";



const Footer = () => {
    return (
        <div id="footer">
        <nav>
           <Link to='/services' title='Création sites internet'>Création de site Internet</Link>
           <Link to='/services' title="Animation de réseaux sociaux">Animation de réseaux sociaux</Link>
           <Link to='/services' title="Création de chartes graphiques">Création de chartes graphiques</Link>
           <Link to='/services' title="Conseil & accompagnement">Conseil & accompagnement</Link>
        </nav>
          <nav>

             <Link to='/agence' title="Kyū Solution">Kyū Solution</Link>
             <Link to='/contact' title="Contact">Contactez-nous</Link>
          </nav>
          <nav>
             <Link to='/politique-de-confidentialite' title="Politique de confidentialité">{'Politique de confidentialité'}</Link>
             <Link to='/mentions-legales' title="Mentions légales">{'Mentions légales'}</Link>

          </nav>
        </div>

    );
  };


export default Footer;
