import React from 'react';
import { Redirect, Link} from "react-router-dom";


class BlockType3 extends React.Component {


  constructor(props) {
      super(props);
      this.state = { redirectToReferrer: false };


      // This binding is necessary to make `this` work in the callback
    }
  state = { redirectToReferrer: false };
  redirect= () => {

      this.setState({ redirectToReferrer: true });

  };

  render() {

    let { from } =  { from: { pathname: "/services" } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;


      return (

        <section className="greyBG">
          <div className="type3 content">
            <h3>Nos domaines d'expertises </h3>
            <p>Notre expertise au service de votre entreprise, pour des projets plus visibles, plus forts, plus impactants</p>




            <article className="mosaic green" onClick={this.redirect}>
             <div className="mask">
                <h2>Sites internet</h2>
                <p>Votre site internet vitrine ou e-commerce à votre image, adapté à tous les supports : ordinateurs, tablettes, téléphones.</p>

              </div>
              <Link to='/services' title='Site internet vitrine & ecommerce'>Sites internet</Link>
              <img src={'./img/kyu-solution-site-internet.jpg'} alt='Site internet vitrine & ecommerce'/>

            </article>


            <article className="mosaic blue" onClick={this.redirect}>
              <div className="mask">
               <h2>Réseaux sociaux</h2>
               <p>Nous créons, gérons et animons vos réseaux sociaux : page Facebook & LinkedIn, compte Pinterest & Twitter & Instagram.</p>
              </div>

              <Link to='/services' title='Réseaux sociaux'>Réseaux sociaux</Link>
              <img src={'./img/kyu-solution-reseaux-sociaux.jpg'} alt='Réseaux sociaux'/>
            </article>

            <article className="mosaic orange" onClick={this.redirect}>
            <div className="mask">
             <h2>Supports print</h2>
              <p>Nous créons vos supports de communication papier : flyers, affiches, cartes de visite, pour mieux faire passer votre message.</p>
              </div>
              <Link to='/services' title='Supports print'>Supports print</Link>
              <img src={'./img/kyu-solution-support-print.jpg'} alt='Supports print'/>
            </article>
            <article className="mosaic yellow" onClick={this.redirect}>
            <div className="mask">
             <h2>Conseil & accompagnement</h2>

              <p>Votre savoir faire est votre meilleur atout, nous vous aidons à mieux le promouvoir.</p>
              </div>
              <Link to='/services' title='Conseil & accompagnement'>Conseil & accompagnement</Link>
              <img src={'./img/kyu-solution-conseil.jpg'} alt='Conseil & accompagnement'/>
            </article>
            </div>

        </section>)
  }
}
export default BlockType3;
