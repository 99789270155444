import React  from 'react';
import Cookie from 'react-cookies';
import {LogoKyu} from './../Assets/';
import { Link } from "react-router-dom";

class CookieBanner extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      rgpd: Cookie.load('rgpd')
    }
  }





  handleAccepted = e => {
    e.preventDefault();

    const value = e.target.value;

    Cookie.save('rgpd',value,{path:'/'});
    window.location.reload();
  };


  render () {

      const { rgpd, visibility} = this.state;



     if(rgpd===undefined ) {

        return (
          <div>
            <div id="cookieBannerPanel">
                <div id="cookieBannerContent">
                  <div>
                    <LogoKyu classname="logo" id="cookieBannerLogo"/>
                    <p>Nous utilisons des cookies et des technologies similaires pour générer des statistiques et vous offrir un contenu personnalisé.</p>
                    <p>Pour nous permettre d'utiliser certains d'entre eux, nous avons besoin de votre accord en cliquant sur le bouton Accepter les Cookies. Si vous souhaitez plus d'informations sur les Cookies que nous utilisons et leurs paramètres, vous pouvez consulter notre  <Link to='/politique-de-confidentialite' title="Politique de confidentialité">{'Politique de confidentialité'}</Link></p>
                    <p>Si vous ne cliquez pas sur Accepter les cookies, nous n'utiliserons que les cookies strictement nécessaires au bon fonctionnement du site.</p>
                  </div>
                  <div>
                    <button type="button" className="btnAccepted" value="true" onClick={e => this.handleAccepted(e)}>Accepter les cookies</button>
                    <button type="button" className="btnRefused" value="false" onClick={e => this.handleAccepted(e)}>Refuser les cookies</button>
                  </div>
                  <button type="button" className="btnClose" value="false" onClick={e => this.handleAccepted(e)}>Fermer</button>
                </div>
            </div>
          </div>
        )

      }
      else {

        return (
          <div>{null}</div>
        )

    }
  }
};


export default CookieBanner;
