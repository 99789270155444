import React, { Component } from 'react';
import { BrowserRouter as Router, Route , Switch} from "react-router-dom";

import Home from './Home';
import Agence from './Agence';
import Services from './Services';
import Contact from './Contact';
import MentionsLegales from './MentionsLegales';
import PolitiqueDeConfidentialite from './PolitiqueDeConfidentialite';
import Demo from './Test';
import NoMatch from './NoMatch';

import  './App.css';

class App extends Component {
  render() {
      return (
        <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/agence" component={Agence} />
          <Route path="/contact" component={Contact} />
          <Route path="/mentions-legales" component={MentionsLegales} />
          <Route path="/politique-de-confidentialite" component={PolitiqueDeConfidentialite} />
          <Route path="/test" component={Demo} />
          <Route component={NoMatch} />
        </Switch>
        </Router>
      )
  }
}
export default App;
