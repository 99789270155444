import React  from 'react';
import MetaTags from 'react-meta-tags';
import Cookie from 'react-cookies';
import {Logo} from './common/Assets/';
import {Header} from './common/Header';
import {Ga} from './common/Header/Ga';
import {Loader} from './common/Assets';
import {ContactForm, Copyright, CookieBanner} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import {BlockType1, BlockType2, BlockType3} from './common/Block';


class Home extends React.Component {

  state = {
    loading: Cookie.load('loading'),
    visibility: 'hidden'
  };

  componentDidMount() {
    //setTimeout(() => this.setState({ loading: false }), 4500);
    setTimeout(() => this.setState({ loading: false }), 0);
  }

  render() {

    const { loading } = this.state;
    const { visibility } = 'hidden';

    Ga();

    if(loading===true ||  loading===undefined) { // if your component doesn't have to wait for an async action, remove this block
      //return null; // render null when app is not ready


      //<Loader/>
     return (
        <div>{null}</div>

      );
    }
    else {
      Cookie.save('loading',false,{path:'/'});

      return (
        <div>

        <MetaTags>
           <title>Kyū Solution créations de sites internet</title>
           <meta name="description" content="Kyū Solution est une agence de communication digitale qui créé des sites internet." />
           <meta name="keywords" content="Kyū Solution, communication digitale, sites internet, réseau sociaux" />

         </MetaTags>
          <div className='mainContent' id="mainContent" style={visibility}>
            <Header title={'Kyū Solution'} headline={'Est une agence de communication digitale et traditionnelle : notre objectif est de rendre votre projet plus visible, plus impactant, plus fort.'} image={'./img/kyu-solution.jpg'}/>
            <main className='container'>
              <BlockType1 sticky={true} title={'Votre entreprise gagne en visibilité'} content={<p>Chez Kyū Solution nous vous accompagnons dans toutes les étapes de la réalisation de votre projet digital : stratégie, design, développement, communication et référencement. Étape par étape, <strong>votre entreprise gagne en visibilité.</strong></p>} />
              <BlockType2 title={'Votre savoir-faire est mis en valeur'} content={<p>Chez Kyū Solution nous cherchons le meilleur point de vue pour mettre en avant votre activité. Nous mettons en place une expérience digitale unique et convaincante. Vous vous démarquez, votre message est plus impactant.</p>}  image1={'./img/kyu-solution-before.jpg'} alt1={'Kyū Solution donne de la visibilité à votre entreprise'} image2={'./img/kyu-solution-after.jpg'}  alt2={'Kyū Solution donne de la visibilité à votre entreprise'}/>
              <BlockType3/>
            </main>
            <ContactForm title={'Parlez-nous de votre projet'}/>
            <SocialNav/>
            <Footer/>
            <Copyright/>
            <Logo/>
            <CookieBanner/>
          </div>
        <SideBar/>
        </div>
      );
    }
  }
}
export default Home;
