import React from 'react';
import {LogoKyu} from './LogoKyu';
import { Link } from "react-router-dom";


const Logo = (props) => {
    return (
      <div className={props.visibility}>
      <Link to='/' title="Kyū Solution"><LogoKyu classname='loaderLogo ' id="logo" /></Link>

      </div>
  );
};

export default Logo;
