import React  from 'react';

const Copyright = () => {
    return (


        <div id="copyright">
        2019 - Copyright Kyū Solution®
        </div>



    );
  };


export default Copyright;
