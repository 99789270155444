import React  from 'react';
import { Link } from "react-router-dom";

const SocialNav = () => {
    return (
      <div id="socialNav">
        <a href="https://www.facebook.com/Kyusolution/" title="Facebook" target="_blank"  rel="noopener noreferrer">Facebook</a>
        <a href="https://www.instagram.com/kyusolution/"  title="Instagram" target="_blank"  rel="noopener noreferrer">Instagram</a>
         <Link to='/contact' title="Contact">{'Contact'}</Link>

      </div>
    );
  };

  export default SocialNav;
