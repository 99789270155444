import React from 'react';
import Slide from 'react-reveal/Slide';
import Reveal from 'react-reveal/Reveal';
import ScrollToTopOnMount from './ScrollToTopOnMount';








const Header = (props) => {

      var headline = '';
      var image = '';
      var content = '';

      if(props.headline) {
        headline = <h2>{props.headline}</h2>
      }

      if(props.image) {
        image = <div className="element ">
                  <div className="zoom">
                      <img src={props.image} alt={props.headline}/>
                  </div>
                </div>
      }
      if(props.content) {
        content = props.content
      }

      return (

        <header>
            <ScrollToTopOnMount/>
            <div className='element'>
              <div className="title">
              <Reveal effect="fadeInUp">
                <h1>{props.title}</h1>
                {headline}
                {content}
              </Reveal>
              </div>
            </div>
            {image}
        </header>);

}

export default Header;
