import React  from 'react';
import MetaTags from 'react-meta-tags';
import {Logo} from './common/Assets/';
import {Header} from './common/Header';
import {ContactForm, Copyright} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import {BlockType1, BlockType2} from './common/Block';

class Agence extends React.Component {

  render() {
      return (
        <div>
          <MetaTags>
           <title>Kyū Solution</title>
           <meta name="description" content="Kyū Solution, c'est 15 années d'expérience dans la communication digitale au service des entreprise dans la création de leurs sites internet vitrine et e-commerce." />
           <meta name="keywords" content="Site internet, freelance" />
           </MetaTags>
          <div className='mainContent' id="mainContent">
            <Header title="L'agence" headline="Kyū Solution, c'est 15 années d'expérience dans la communication digitale au service de votre entreprise." image={'../img/kyu-solution-agence.jpg'}/>
            <main>
            <BlockType1 sticky={true} title="Kyū 級" content={[<p>Kyū est un terme utilisé dans les arts martiaux pour signaler les différentes étapes de progression, marquées par l'obtention d'une ceinture de couleur.</p>,<p>Chez Kyū Solution, nous considérons la communication comme un art dont il est parfois difficile de maîtriser tous les codes. Nous accompagnons nos clients dans la mise en place de dispositifs personnalisés et adaptés à leurs cibles.</p>,<p>Étape par étape, la visibilité de leur entreprise progresse, elle gagne en notoriété</p>]}/>
            <BlockType2 title="Qui sommes nous ?" content={<p>Après un parcours de 15 années en agence de communication digitale en tant que développeur puis chef de projet pour le compte de PME et de grands groupes, Cindy PONCIN crée l'agence Kyū Solution dans le but de rendre accessible aux petites et moyennes entreprises les moyens de communication utilisés par les grandes entreprises.</p>}/>
            </main>
            <ContactForm title={'Parlez-nous de votre projet'} styleName={'greyBG'}/>
            <SocialNav/>
            <Footer/>
            <Copyright/>
            <Logo/>
          </div>
        <SideBar/>
        </div>
        )
    }
}
export default Agence;
