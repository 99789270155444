import ReactGA from 'react-ga';
import Cookie from 'react-cookies';

export function Ga() {

      const  rgpd = Cookie.load('rgpd');

      if(rgpd==='true') {

        ReactGA.initialize('UA-133463131-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

}
