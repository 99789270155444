import React from 'react';
import { Sticky,StickyContainer } from 'react-sticky';
import { Link } from "react-router-dom";

class BlockType1 extends React.Component {


  constructor(props) {
      super(props);
      this.state = {
        content:'',
        link:'',
        linkTitle:''
    }

    if(props.content) {
      this.state.content = '';
    }

    if(props.link) {
      this.state.link = <Link to={this.props.link} title={this.props.linkTitle} data-hover={this.props.linkTitle}>{this.props.linkTitle}</Link>;
    }

    if(props.sticky) {

      this.state.content =
      <StickyContainer>
        <section className="greyBG sticky" >
        <Sticky>{({ style ,distanceFromTop }) =>  <div style={style}>
          <div className="content type1" >
            <article className="article">
              <h3>{this.props.title}</h3>
              {this.props.content}
              {this.state.link}
            </article>
          </div>
          </div>
        }
        </Sticky>
        </section>
      </StickyContainer>
    }
    else {
      this.state.content =   <section >
      <div className="content type1" >
        <article className="article">
          <h3>{this.props.title}</h3>
          {this.props.content}
          {this.state.link}
        </article>
      </div>
      </section>

    }

  }


  render() {
      return (
          <div>
              {this.state.content}
          </div>
      )
  }
}
export default BlockType1;
