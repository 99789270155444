import React, { Component, PropTypes }   from 'react';
import { render } from "react-dom";
import { Spring } from 'react-spring';

import MetaTags from 'react-meta-tags';
import Cookie from 'react-cookies';
import {Logo} from './common/Assets/';
import {Header} from './common/Header';
import {Loader} from './common/Assets';
import {ContactForm, Copyright} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import {BlockType1, BlockType2, BlockType3} from './common/Block';
import { Scroller, scrollInitalState } from "react-skroll";


function round(val) {
  return (Math.round(val * 100) / 100).toFixed(2);
}

const colors = [
  {name: "Blue", color: "#215cf4" },
  {name: "Cyan", color: "#0ccabf" },
  {name: "Green", color: "#4ac36c" },
  {name: "Yellow", color: "#e0be18" },
  {name: "Red", color: "#e91e4f" },
  {name: "Magenta", color: "#ca28e4" },
]



class Demo extends Component {
  constructor() {
    super()

    this.state = {
      scroll: scrollInitalState
    }


  }

  render() {
    const { scroll } = this.state



    return (
      <div>
      <MetaTags>
         <title>Kyū Solution créations de sites internet</title>
         <meta name="description" content="Kyū Solution est une agence de communication digitale qui créé des sites internet." />
         <meta name="keywords" content="Kyū Solution, communication digitale, sites internet, réseau sociaux" />
       </MetaTags>
       <div className="wrapper" id='mainContent'>

       <Scroller
         scrollRef={ref => this.scroll = ref}
         autoScroll={true}
         autoFrame={true}
         onScrollChange={(scroll) => this.setState({ scroll })}
       >


         <Header key={1} name={'header'}  title={'Kyū Solution'} headline={'Est une agence de communication digitale et traditionnelle : notre objectif est de rendre votre projet plus visible, plus impactant, plus fort.'} image={'./img/kyu-solution.jpg'}/>
         <BlockType1 key={2} name={'blockType1'}  sticky={true} title={'Votre entreprise gagne en visibilité'} content={<p>Chez Kyū Solution nous vous accompagnons dans toutes les étapes de la réalisation de votre projet digital : stratégie, design, développement, communication et référencement. Étape par étape, votre entreprise gagne en visibilité.</p>}/>
         <BlockType2 key={3} name={'blockType2'} title={'Votre savoir-faire est mis en valeur'} content={<p>Chez Kyū Solution nous cherchons le meilleur point de vue pour mettre en avant votre activité. Nous mettons en place une expérience digitale unique et convaincante. Vous vous démarquez, votre message est plus impactant.</p>}  image1={'./img/kyu-solution-before.jpg'} alt1={'Kyū Solution donne de la visibilité à votre entreprise'} image2={'./img/kyu-solution-after.jpg'}  alt2={'Kyū Solution donne de la visibilité à votre entreprise'}/>
         <BlockType3/>
         <ContactForm title={'Parlez-nous de votre projet'}/>
         <SocialNav/>
         <Footer/>
         <Copyright/>

         </Scroller>

         <Logo visibility={round(scroll.positionRelativeRatio)<1 ? 'active' : 'inactive'}/>
        </div>
      <SideBar/>
      </div>
    )
  }
}

export default Demo;



/*
<Scroller
  scrollRef={ref => this.scroll = ref}
  autoScroll={true}
  autoFrame={true}
  onScrollChange={(scroll) => this.setState({ scroll })}
>
  {
    colors.map(({ name, color }, index) =>
      <section key={index} name={name} style={{background: color}}>
        <div className="flex center-center half-width">
          <h1>{round(scroll.positionRatio)}</h1>
        </div>

        <div className="flex left-center half-width">
          <ul>
            <p>{'{'}</p>

            {
              Object.entries(scroll)
                .filter(([key, value]) => typeof value !== 'function')
                .filter(([key, value]) => typeof value !== 'object')
                .map(([key, value]) =>
                  <li key={key}>
                    <span>{key}: </span>
                    <span className={value ? 'active' : 'inactive'}>{value.toString()}</span>
                  </li>
              )
            }

            <li>...</li>
            <p>{'}'}</p>
          </ul>
        </div>


      </section>
    )
  }
</Scroller>*/

/*
{
  colors.map(({ name, color }, index) =>
    <section key={index} name={name} style={{background: color}} className={index<1 ? 'active' : 'inactive'}>
      <div className="flex center-center half-width">
        <h1>{round(scroll.positionRatio)}</h1>
      </div>

      <div className="flex left-center half-width">
        <ul>
          <p>{'{'}</p>

          {
            Object.entries(scroll)
              .filter(([key, value]) => typeof value !== 'function')
              .filter(([key, value]) => typeof value !== 'object')
              .map(([key, value]) =>
                <li key={key}>
                  <span>{key}: </span>
                  <span className={value ? 'active' : 'inactive'}>{value.toString()}</span>
                </li>
            )
          }

          <li>...</li>
          <p>{'}'}</p>
        </ul>
      </div>


    </section>
  )
}*/
