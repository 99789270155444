import React from 'react';
import {push as Menu} from 'react-burger-menu';
import { Link } from "react-router-dom";


const SideBar = () => {
    return (
    <div>
    <Menu noOverlay right width={ 300 } isOpen={ false } className="bm-menu" pageWrapId={ "mainContent" }  outerContainerId={ "root" } customCrossIcon={ <img src={'https://www.kyu-solution.fr/img/arrow.svg'} alt="Fermer le menu"/> } >

          <Link to='/' className="bm-item-list" title="Accueil" >Accueil</Link>
          <Link to='/services' className="bm-item-list" title="Nos services" >Nos services</Link>
          <Link to='/agence' className="bm-item-list" title="L'agence">L'agence</Link>
          <Link to='/contact' className="bm-item-list" title="Contactez-nous">Contactez-nous</Link>
    </Menu>
    <div id="load"></div>
    </div>
    );
};

export default SideBar;
