import React from 'react';
import MetaTags from 'react-meta-tags';
class NoMatch extends React.Component {

  render() {
      return (
        <div>
        <MetaTags>
           <title>Kyū Solution Page introuvable</title>
           <meta name="description" content="Page introuvable" />
           <meta name="keywords" content="Page introuvable" />
        </MetaTags>
        <main>
        <div className='mainContent'>
          Page introuvable
        </div>
        </main>
        </div>
      )
  }
}
export default NoMatch;
