import React from 'react';

export const LogoKyu = (props) => {
    const { classname, id, fill } = props;
    return (
      <svg className={classname} id={id} version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="1740.000000pt" height="1020.000000pt" viewBox="0 0 1740.000000 1020.000000"
       preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,1020.000000) scale(0.100000,-0.100000)"
      fill={fill} stroke="none">
      <path  d="M370 5740 l0 -4200 310 0 310 0 0 1103 1 1102 937 961 c515 529 940
      959 944 957 4 -2 816 -931 1804 -2063 l1797 -2060 380 0 c361 0 379 1 365 18
      -8 9 -896 1040 -1973 2291 l-1957 2273 44 47 c195 202 3456 3571 3542 3659
      l111 112 -382 0 -382 0 -2613 -2691 -2613 -2691 -3 2691 -2 2691 -310 0 -310
      0 0 -4200z"/>
      <path  className="bullet" d="M12310 9397 c-283 -96 -394 -453 -216 -695 145 -197 432 -243 630
      -101 249 178 249 560 0 738 -113 81 -279 104 -414 58z"/>
      <path d="M7370 7930 l0 -310 4830 0 4830 0 0 310 0 310 -4830 0 -4830 0 0
      -310z"/>
      <path d="M7375 7270 c3 -6 512 -1128 1130 -2495 l1123 -2485 -162 -363 c-88
      -199 -190 -413 -225 -475 -206 -366 -428 -558 -731 -629 -96 -23 -399 -25
      -505 -4 -110 22 -242 68 -334 117 -46 24 -85 44 -87 44 -3 0 -3 -111 -2 -246
      l3 -246 50 -19 c76 -29 280 -77 375 -89 127 -15 397 -12 517 6 527 78 921 416
      1224 1048 71 150 2629 5804 2636 5829 5 16 -11 17 -244 17 l-249 0 -999 -2230
      c-549 -1227 -1001 -2227 -1005 -2223 -4 4 -454 1008 -1001 2230 l-994 2223
      -263 0 c-174 0 -261 -3 -257 -10z"/>
      <path d="M12580 5702 c0 -1660 0 -1679 45 -1915 148 -783 620 -1294 1370
      -1482 236 -60 336 -70 665 -70 327 0 403 8 646 70 532 136 958 464 1213 935
      l41 75 0 -522 0 -523 235 0 235 0 0 2505 0 2505 -245 0 -244 0 -4 -1462 c-2
      -1085 -7 -1484 -16 -1543 -41 -274 -102 -479 -202 -680 -87 -177 -176 -302
      -309 -435 -265 -265 -596 -420 -1013 -475 -137 -17 -404 -20 -545 -4 -735 80
      -1204 505 -1341 1214 -44 228 -44 221 -48 1833 l-4 1552 -240 0 -239 0 0
      -1578z"/>
      <path d="M13610 960 l0 -480 55 0 55 0 0 480 0 480 -55 0 -55 0 0 -480z"/>
      <path d="M15254 1432 c-25 -17 -36 -53 -24 -82 31 -74 140 -51 140 29 0 45
      -76 80 -116 53z"/>
      <path d="M12213 1382 c-83 -30 -128 -65 -157 -123 -44 -90 -32 -195 32 -262
      41 -43 103 -71 252 -111 163 -44 219 -75 239 -132 18 -50 10 -83 -30 -129 -77
      -87 -300 -87 -439 0 -21 13 -41 29 -45 35 -11 18 -22 11 -40 -30 -18 -39 -18
      -41 1 -61 52 -58 186 -99 323 -99 221 0 341 88 341 250 0 138 -68 195 -310
      260 -192 51 -246 90 -244 175 1 54 36 104 91 131 30 15 60 19 143 19 98 0 110
      -2 178 -34 40 -19 73 -32 75 -30 1 2 10 21 20 41 16 37 16 38 -8 54 -63 41
      -129 57 -250 61 -98 3 -129 0 -172 -15z"/>
      <path d="M14770 1235 l0 -75 -60 0 -60 0 0 -40 0 -40 60 0 60 0 1 -232 c1
      -281 10 -315 95 -358 70 -36 233 -20 250 24 3 8 -2 28 -11 45 -15 31 -16 31
      -46 15 -37 -18 -108 -21 -139 -4 -43 23 -50 64 -50 295 l0 215 105 0 105 0 0
      40 0 40 -105 0 -105 0 0 75 0 75 -50 0 -50 0 0 -75z"/>
      <path d="M12990 1153 c-137 -48 -220 -169 -222 -323 -1 -82 8 -122 39 -183 30
      -60 89 -118 151 -148 71 -34 216 -39 294 -9 124 46 207 163 216 306 10 162
      -74 301 -215 354 -67 25 -197 27 -263 3z m255 -105 c52 -33 70 -54 96 -113 43
      -95 23 -231 -44 -304 -54 -59 -168 -86 -258 -61 -47 13 -114 72 -142 124 -31
      58 -31 193 0 251 27 50 77 99 123 120 55 24 174 16 225 -17z"/>
      <path d="M15710 1153 c-137 -48 -220 -169 -222 -323 -1 -82 8 -122 39 -183 30
      -60 89 -118 151 -148 71 -34 216 -39 294 -9 124 46 207 163 216 306 10 162
      -74 301 -215 354 -67 25 -197 27 -263 3z m255 -105 c52 -33 70 -54 96 -113 43
      -95 23 -231 -44 -304 -54 -59 -168 -86 -258 -61 -47 13 -114 72 -142 124 -31
      58 -31 193 0 251 27 50 77 99 123 120 55 24 174 16 225 -17z"/>
      <path d="M16580 1161 c-47 -15 -95 -44 -122 -74 l-28 -29 0 51 0 51 -50 0 -50
      0 0 -340 0 -340 55 0 55 0 0 206 c0 237 6 269 65 330 62 65 175 84 261 45 89
      -41 104 -92 104 -368 l0 -213 50 0 50 0 0 233 c0 260 -7 305 -61 370 -50 61
      -99 80 -209 84 -52 1 -106 -1 -120 -6z"/>
      <path d="M13920 927 c0 -254 7 -300 54 -362 15 -19 48 -47 73 -62 40 -23 61
      -28 140 -31 110 -5 164 11 225 66 l38 34 0 -46 0 -46 50 0 50 0 0 340 0 340
      -49 0 -49 0 -4 -213 -3 -213 -30 -60 c-26 -51 -38 -63 -84 -86 -95 -48 -219
      -30 -268 38 -36 51 -43 101 -43 322 l0 212 -50 0 -50 0 0 -233z"/>
      <path d="M15240 820 l0 -340 55 0 55 0 0 340 0 340 -55 0 -55 0 0 -340z"/>
      <path className="belt" d="M360 730 l0 -240 3430 0 3430 0 0 240 0 240 -3430 0 -3430 0 0 -240z"/>
      </g>
      </svg>
  )
}

export default LogoKyu;
