import React  from 'react';
import MetaTags from 'react-meta-tags';
import {Logo} from './common/Assets/';
import {Header} from './common/Header';
import {ContactForm, Copyright, CookieBanner} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import {BlockType1} from './common/Block';



class Services extends React.Component {

  render() {
      return (
        <div>
        <MetaTags>
           <title>Création de sites internet</title>
           <meta name="description" content="Des sites internet uniques et de qualité. Du design au développement de votre site internet Kyū Solution vous propose des sites internet adaptés aux besoins de votre entreprise." />
           <meta name="keywords" content="Site internet, design, websign,création , développement" />
         </MetaTags>
          <div className='mainContent' id="mainContent">
            <Header title={'Nos services'} headline={'Kyū solution dispose d\'un large champ de compétences au service de votre entreprise.'} image={'../img/kyu-solution-services.jpg'}/>
            <main >
              <BlockType1 sticky={true} title={'Votre savoir faire mis en valeur'} content={<p>Kyū solution vous conseille et met en place des actions autour de la communication de votre entreprise. <br/>Ces actions vont de la création d'un logo, à la réalisation de cartes de visite ou de supports publicitaires. Kyū Solution réalise également des sites internet adaptés au besoin de votre entreprise et valorisant votre savoir faire.</p>}/>
              <section className="service">
                <article className=" blue">
                  <div className="col1">
                    <h3>Création de sites internet</h3>
                    <p>Adapté à tous les supports : ordinateurs, tablettes, téléphones.
                    Un site internet sur mesure et à votre image. Il saura s'adapter à tous les supports</p>
                  </div>
                  <div className="col2">
                    <img src={'../img/kyu-solution-site-internet.jpg'} alt="Site internet"/>
                  </div>
                </article>
                <article className=" green">
                <div className="col1">
                  <h3>Création & animation des réseaux sociaux</h3>
                  <p>Nous créons, gérons et animons vos réseaux sociaux : page Facebook & LinkedIn, compte Pinterest & Twitter & Instagram en postant votre contenu ou bien en créant du contenu</p>
                  </div>
                  <div className="col2">
                    <img src={'../img/kyu-solution-reseaux-sociaux.jpg'} alt="réseaux sociaux"/>
                  </div>
                </article>
                <article className=" orange">
                <div className="col1">
                  <h3>Création de supports print</h3>
                  <p>Nous créons vos supports de communication papier : flyers, affiches, cartes de visite, pour mieux faire passer votre message et permettre à vos futurs clients de comprendre plus facilement ce que vous faites.</p>
                  </div>
                  <div className="col2">
                    <img src={'../img/kyu-solution-support-print.jpg'} alt="Création de supports print"/>
                  </div>
                </article>
                <article className=" yellow">
                <div className="col1">
                  <h3>Conseil & accompagnement</h3>
                  <p>Nous créons vos supports de communication papier : flyers, affiches</p>
                  </div>
                  <div className="col2">
                    <img src={'../img/kyu-solution-conseil.jpg'} alt="Conseil & accompagnement"/>
                  </div>
                </article>
              </section>
            </main>
              <ContactForm title={'Parlez-nous de votre projet'} styleName={'greyBG'}/>
            <SocialNav/>
            <Footer/>
            <Copyright/>
            <Logo/>
            <CookieBanner/>
          </div>
        <SideBar/>
        </div>
      )
  }
}

export default Services;
