Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BlockType1 = exports.BlockType2 = exports.BlockType3 = undefined;

var _BlockType1 = require("./BlockType1");

var _BlockType12 = _interopRequireDefault(_BlockType1);

var _BlockType2 = require("./BlockType2");

var _BlockType22 = _interopRequireDefault(_BlockType2);

var _BlockType3 = require("./BlockType3");

var _BlockType32 = _interopRequireDefault(_BlockType3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.BlockType1 = _BlockType12.default;
exports.BlockType2 = _BlockType22.default;
exports.BlockType3 = _BlockType32.default;
exports.default = _BlockType12.default;
