import React  from 'react';
import {Logo} from './common/Assets/';
import {Header} from './common/Header';
import {ContactForm, Copyright, CookieBanner} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import MetaTags from 'react-meta-tags';


// Contact component render contact form
class Contact extends React.Component {

  render() {

    return (
      <div>
      <MetaTags>
       <title>Contact Kyū Solution</title>
       <meta name="description" content="Contacter Kyū Solution et demander un devis pour votre site internet, site ecommerce et pour la gestion et l'animation de vos réseaux sociaux." />
       <meta name="keywords" content="Kyū Solution, projet site internet, devis site internet" />
       </MetaTags>
        <div className='mainContent' id="mainContent">
            <main>
            <Header title={'Contact'} headline={'8, avenue du Marchéal Leclerc - 63800 COURNON d'AUVERGNE'} content={<p className='ContactAddress'>
              <a href="mailto:contact@kyu-solution.fr">contact@kyu-solution.fr</a><br/>
              <a href="tel:+33677230258">06 77 23 02 58</a>
            </p>} image={'../img/kyu-solution-contact.jpg'}/>
          </main>
          <SocialNav/>

          <ContactForm title={'Parlez-nous de votre projet'} styleName={'greyBG'}/>
          <Footer/>
          <Copyright/>
          <Logo/>
          <CookieBanner/>
        </div>
      <SideBar/>
      </div>
    )
  }
}

export default Contact;
