Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideBar = exports.SocialNav = exports.Footer = undefined;

var _SideBar = require("./SideBar");
var _SideBar2 = _interopRequireDefault(_SideBar);

var _SocialNav = require("./SocialNav");
var _SocialNav2 = _interopRequireDefault(_SocialNav);

var _Footer = require("./Footer");
var _Footer2 = _interopRequireDefault(_Footer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.SideBar  = _SideBar2.default;
exports.SocialNav = _SocialNav2.default;
exports.Footer = _Footer2.default;

exports.default = _SideBar2.default;
