import React  from 'react';
import { Sticky,StickyContainer } from 'react-sticky';
import Bounce from 'react-reveal/Bounce';


class BlockType2 extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        title:'',
        content:'',
        image1:'',
        alt1:'',
        image2:'',
        alt2:'',
        styleName:''
    }

    if(props.title) {
      this.state.title = <h3 className="texte">{this.props.title}</h3>
    }
    if(props.content) {
      this.state.content = this.props.content
    }

    if(props.image1) {
      this.state.image1 = <div className="image"><img src={props.image1} alt={props.alt1}/></div>
    }
    if(props.image2) {
      this.state.image2 = <div className="image"><img src={props.image2} alt={props.alt2}/></div>
    }
    if(props.styleName) {
      this.state.styleName = props.styleName;
    }
  }

  render() {
      return (
        <StickyContainer>
        <section className={this.state.styleName} >
        <Sticky>{({ style ,distanceFromTop }) =>  <div style={style}>
            <div className="content type2">
              <div className="element" >
                  {this.state.image1}
                  {this.state.image2}
              </div>

              <div className="element" >
                  <Bounce right>
                  {this.state.title}
                  {this.state.content}
                  </Bounce>
              </div>
            </div>
            </div>
          }
          </Sticky>
        </section >
        </StickyContainer>
      )
    }
}
export default BlockType2;
