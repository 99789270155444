Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactForm = exports.Copyright = exports.CookieBanner = undefined;

var _ContactForm = require("./ContactForm");

var _ContactForm2 = _interopRequireDefault(_ContactForm);

var _Copyright = require("./Copyright");

var _Copyright2 = _interopRequireDefault(_Copyright);

var _CookieBanner = require("./CookieBanner");

var _CookieBanner2 = _interopRequireDefault(_CookieBanner);



function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.ContactForm  = _ContactForm2.default;
exports.Copyright = _Copyright2.default;
exports.CookieBanner = _CookieBanner2.default;

exports.default = _ContactForm2.default;
