import React  from 'react';
import MetaTags from 'react-meta-tags';
import {Logo} from './common/Assets/';
import {ScrollToTopOnMount} from './common/Header';
import {Copyright} from './common/Footer';
import {SideBar, SocialNav, Footer} from './common/Nav';
import {BlockType1} from './common/Block';


const Politique = (props) => {

    return (<div><ScrollToTopOnMount/>
      <h1>Politique de confidentialité</h1>
<p>Sécurité et protection des données personnelles</p>
<h3>Avertissements</h3>
<p>Le présent outil est mis à votre disposition gratuitement. L'outil se base sur des informations fondées sur
l’analyse professionnelle du cabinet en matière de conformité RGPD. Toutefois, la conformité étant un
processus dynamique et toute situation étant particulière, les informations transmises doivent être adaptées et
ne peuvent en aucun cas être considérées comme exhaustives ou exactes.</p>
<p>Sauf à ce que vous demandiez une revue et validation par le Cabinet, le document généré est considéré
comme une simple information. En conséquence, vous êtes seul responsable des interprétations faites des
informations fournies, des conseils que vous en déduisez et des adaptations réalisées pour votre activité
commerciale propre. L'utilisation et l'exploitation de l'outil se fait donc sous votre seule responsabilité et à vos risques et périls.</p>
<h3>Définitions :</h3>
<p>L'Éditeur : La personne, physique ou morale, qui édite les services de communication au public en ligne.<br/>
Le Site : L'ensemble des sites, pages Internet et services en ligne proposés par l'Éditeur.<br/>
L'Utilisateur : La personne utilisant le Site et les services.</p>
<h3>Nature des données collectées</h3>
<p>Dans le cadre de l'utilisation des Sites, l'Éditeur est susceptible de collecter les catégories de données
suivantes concernant ses Utilisateurs :<br/>
Données d'état-civil, d'identité, d'identification...<br/>
Données de connexion (adresses IP, journaux d'événements...)<br/>
Données de localisation (déplacements, données GPS, GSM...)</p>
<h3>Information préalable pour la communication des données personnelles à des tiers en
cas de fusion / absorption</h3>
<p>
Vos données ne font l'objet d'aucune communication à des tiers. Vous êtes toutefois informés qu'elles
pourront être divulguées en application d'une loi, d'un règlement ou en vertu d'une décision d'une autorité
réglementaire ou judiciaire compétente.</p>
<h3>Information préalable pour la communication des données personnelles à des tiers en
cas de fusion / absorption</h3>
<p>Collecte de l’opt-in (consentement) préalable à la transmission des données suite à une fusion /
acquisition<br/>
Dans le cas où nous prendrions part à une opération de fusion, d’acquisition ou à toute autre forme de cession
d’actifs, nous nous engageons à obtenir votre consentement préalable à la transmission de vos données
personnelles et à maintenir le niveau de confidentialité de vos données personnelles auquel vous avez
consenti.</p>
<h3>Finalité de la réutilisation des données personnelles collectées</h3>
<h4>Effectuer les opérations relatives à la gestion des clients concernant</h4>
<ul>
<li>les contrats ; les commandes ; les livraisons ; les factures ; la comptabilité et en particulier la gestion
des comptes clients</li>
<li>un programme de fidélité au sein d'une entité ou plusieurs entités juridiques ;
le suivi de la relation client tel que la réalisation d'enquêtes de satisfaction, la gestion des réclamations
et du service après-vente</li>
<li>la sélection de clients pour réaliser des études, sondages et tests produits (sauf consentement des
personnes concernées recueilli dans les conditions prévues à l’article 6, ces opérations ne doivent pas
conduire à l'établissement de profils susceptibles de faire apparaître des données sensibles - origines
raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé
des personnes)</li></ul>
<h4>L'élaboration de statistiques commerciales</h4>
<h3>Agrégation des données</h3>
<h4>Agrégation avec des données non personnelles</h4>
<p>Nous pouvons publier, divulguer et utiliser les informations agrégées (informations relatives à tous nos
Utilisateurs ou à des groupes ou catégories spécifiques d'Utilisateurs que nous combinons de manière à ce
qu'un Utilisateur individuel ne puisse plus être identifié ou mentionné) et les informations non personnelles à
des fins d'analyse du secteur et du marché, de profilage démographique, à des fins promotionnelles et
publicitaires et à d'autres fins commerciales.</p>
<h4>Agrégation avec des données personnelles disponibles sur les comptes sociaux de l'Utilisateur</h4>
<p>Si vous connectez votre compte à un compte d’un autre service afin de faire des envois croisés, ledit service
pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont
vous avez autorisé la divulgation. Nous pouvons agréger les informations relatives à tous nos autres
Utilisateurs, groupes, comptes, aux données personnelles disponibles sur l’Utilisateur.</p>
<h3>Collecte des données d'identité</h3>
<p>La consultation du Site ne nécessite pas d'inscription ni d'identification préalable. Elle peut s'effectuer sans que
vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Nous ne
procédons à aucun enregistrement de données nominatives pour la simple consultation du Site.</p>
<h3>Collecte des données du terminal</h3>
<h4>Certaines des données techniques de votre appareil sont collectées automatiquement par le Site.</h4>
<p>Ces informations incluent notamment votre adresse IP, fournisseur d'accès à Internet, configuration matérielle,
configuration logicielle, type et langue du navigateur...</p>
<h4>Les données techniques de votre appareil sont automatiquement collectées et enregistrées par le Site, à des
fins statistiques.</h4>
<p>Ces informations nous aident à personnaliser et à améliorer
continuellement votre expérience sur notre Site. Nous ne collectons ni ne conservons aucune donnée
nominative (nom, prénom, adresse...) éventuellement attachée à une donnée technique. Les données collectées
sont susceptibles d’être revendues à des tiers.</p>
<h3>Cookies</h3>
<h4>Durée de conservation des cookies</h4>
<p>Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13
mois au maximum après leur premier dépôt dans le terminal de l'Utilisateur, tout comme la durée de la validité
du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas prolongée
à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l'issue de ce délai.</p>
<h4>Finalité cookies</h4>
<p>l'Utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des
pages ainsi que les opérations réalisées et les informations consultées.
Vous êtes informé que l'Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie enregistre
des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l'heure
de la consultation...) que nous pourrons lire lors de vos visites ultérieures.</p>
<h4>Droit de l'Utilisateur de refuser les cookies</h4>
<p>Vous reconnaissez avoir été informé que l'Éditeur peut avoir recours à des cookies. Si vous ne souhaitez pas
que des cookies soient utilisés sur votre terminal, la plupart des navigateurs vous permettent de désactiver les
cookies en passant par les options de réglage.</p>
<h3>Conservation des données techniques</h3>
<h4>Durée de conservation des données techniques</h4>
<p>Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités
visées ci-avant.</p>
<h3>Délai de conservation des données personnelles et d'anonymisation</h3>
<h4>Conservation des données pendant la durée de la relation contractuelle</h4>
<p>Conformément à l'article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
libertés, les données à caractère personnel faisant l'objet d'un traitement ne sont pas conservées au-delà du
temps nécessaire à l'exécution des obligations définies lors de la conclusion du contrat ou de la durée
prédéfinie de la relation contractuelle.</p>
<h4>Conservation des données anonymisées au delà de la relation contractuelle / après la suppression du
compte</h4>
<p>Nous conservons les données personnelles pour la durée strictement nécessaire à la réalisation des finalités
décrites dans les présentes CGU. Au-delà de cette durée, elles seront anonymisées et conservées à des fins
exclusivement statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.</p>

<h3>Indications en cas de faille de sécurité décelée par l'Éditeur</h3>
<h4>Information de l'Utilisateur en cas de faille de sécurité</h4>
<p>Nous nous engageons à mettre en oeuvre toutes les mesures techniques et organisationnelles appropriées afin
de garantir un niveau de sécurité adapté au regard des risques d'accès accidentels, non autorisés ou illégaux,
de divulgation, d'altération, de perte ou encore de destruction des données personnelles vous concernant.
Dans l'éventualité où nous prendrions connaissance d'un accès illégal aux données personnelles vous
concernant stockées sur nos serveurs ou ceux de nos prestataires, ou d'un accès non autorisé ayant pour
conséquence la réalisation des risques identifiés ci-dessus, nous nous engageons à :</p>
<ul>
<li>Vous notifier l'incident dans les plus brefs délais ;</li>
<li>Examiner les causes de l'incident et vous en informer ;</li>
<li>Prendre les mesures nécessaires dans la limite du raisonnable afin d'amoindrir les effets négatifs et
préjudices pouvant résulter dudit incident</li>
</ul>

<h4>Limitation de la responsabilité</h4>
<p>En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille de sécurité ne
peuvent être assimilés à une quelconque reconnaissance de faute ou de responsabilité quant à la survenance
de l'incident en question.</p>

<h3>Transfert des données personnelles à l'étranger</h3>
<p>L'Éditeur s'engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l'Union
européenne.</p>

<h3>Modification des CGU et de la politique de confidentialité</h3>
<p>En cas de modification des présentes CGU, engagement de ne pas baisser le niveau de confidentialité de
manière substantielle sans l'information préalable des personnes concernées<br/>
Nous nous engageons à vous informer en cas de modification substantielle des présentes CGU, et à ne pas
baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et obtenir
votre consentement.</p>
<h3>Droit applicable et modalités de recours</h3>
<p>Application du droit français (législation CNIL) et compétence des tribunaux
Les présentes CGU et votre utilisation du Site sont régies et interprétées conformément aux lois de France, et
notamment à la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Le choix de
la loi applicable ne porte pas atteinte à vos droits en tant que consommateur conformément à la loi applicable
de votre lieu de résidence. Si vous êtes un consommateur, vous et nous acceptons de se soumettre à la
compétence non-exclusive des juridictions françaises, ce qui signifie que vous pouvez engager une action
relative aux présentes CGU en France ou dans le pays de l'UE dans lequel vous vivez. Si vous êtes un
professionnel, toutes les actions à notre encontre doivent être engagées devant une juridiction en France.
En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d'échec de
ces tentatives, toutes contestations à la validité, l'interprétation et / ou l'exécution des présentes CGU devront
être portées même en cas de pluralité des défendeurs ou d'appel en garantie, devant les tribunaux français.</p>
<h3>Portabilité des données</h3>
<p>L'Éditeur s'engage à vous offrir la possibilité de vous faire restituer l'ensemble des données vous concernant
sur simple demande. L'Utilisateur se voit ainsi garantir une meilleure maîtrise de ses données, et garde la
possibilité de les réutiliser. Ces données devront être fournies dans un format ouvert et aisément réutilisable.</p></div>);
}

class PolitiqueDeConfidentialite extends React.Component {

  render() {
      return (
        <div>
          <MetaTags>
           <title>Politique de confidentialité</title>
           <meta name="description" content="Kyū Solution, c'est 15 années d'expérience dans la communication digitale au service des entreprise dans la création de leurs sites internet vitrine et e-commerce." />
           <meta name="keywords" content="Site internet, freelance" />
           </MetaTags>
          <div className='mainContent' id="mainContent">
            <main>
            <BlockType1 content={<Politique/>}/>
            </main>
            <SocialNav/>
            <Footer/>
            <Copyright/>
            <Logo/>

          </div>
        <SideBar/>
        </div>
        )
    }
}
export default PolitiqueDeConfidentialite;
