import React from 'react';
import axios from 'axios';
import FormErrors from '../../FormErrors.js';



const API_PATH = 'https://www.kyu-solution.fr/api/contact/index.php';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title:'',
      content:'Vous souhaitez un devis ou en savoir plus sur nous, vous pouvez utiliser le formulaire ci-dessous',
      styleName:'',
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
      mailSent: false,
      error: null,
      formErrors: {email: '', name: '', message:''},
      emailValid: true,
      nameValid: true,
      messageValid: true,
      rgpdCheckedValid:'',
      emailStyle: '',
      companyStyle: '',
      phoneStyle: '',
      nameStyle: '',
      messageStyle: '',
      rgpdCheckedStyle: '',
      formValid: false
    }

    if(props.title) {
      this.state.title = <h3>{props.title}</h3>
    }

    if(props.styleName) {
      this.state.styleName = props.styleName
    }
  }


  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let messageValid = this.state.messageValid;
    let phoneValid = this.state.phoneValid;
    let companyValid = this.state.companyValid;
    let rgpdCheckedValid = this.state.rgpdCheckedValid;

    let emailStyle='';
    let nameStyle='';
    let messageStyle='';
    let companyStyle='';
    let phoneStyle='';
    let rgpdCheckedStyle=''



    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? true : false;
        emailStyle = emailValid ? 'success' : 'error';
        this.setState({emailStyle:emailStyle});
        break;
      case 'name':
        nameValid = value.length >= 4;
        fieldValidationErrors.name = nameValid ? true: false;
        nameStyle = nameValid ? 'success' : 'error';
        this.setState({nameStyle:nameStyle});
        break;
      case 'message':
          messageValid = value.length >= 10;
          fieldValidationErrors.message = messageValid ? true: false;

          messageStyle = messageValid ? 'success' : 'error';
          this.setState({messageStyle:messageStyle});
          break;
      case 'company':
              companyValid = value.length >= 0;
              fieldValidationErrors.phone = companyValid ? true: false;
              companyStyle = companyValid ? 'success' : '';
              this.setState({companyStyle:companyStyle});
              break;
      case 'phone':
              phoneValid = value.match(/^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*)*$/i);
              fieldValidationErrors.phone = phoneValid ? true: false;
              phoneStyle = phoneValid ? 'success' : 'error';

              this.setState({phoneStyle:phoneStyle});
              break;
      case 'rgpdChecked':
              rgpdCheckedValid = value === "1";
              fieldValidationErrors.rgpdChecked = rgpdCheckedValid ? true: false;
              rgpdCheckedStyle = rgpdCheckedValid ? 'success' : '';
              this.setState({rgpdCheckedStyle:rgpdCheckedStyle});
              break;
      default:
              break;



    }

    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    nameValid: nameValid,
                    messageValid: messageValid,
                    rgpdCheckedValid :rgpdCheckedValid
                  }, this.validateForm);


  }

  validateForm() {
    this.setState({formValid: this.state.emailValid && this.state.nameValid && this.state.messageValid && this.state.rgpdCheckedValid});
  }

  handleUserInput (e) {
  const name = e.target.name;
  const value = e.target.value;

  this.setState({[name]: value},
                () => { this.validateField(name, value) });
}

  handleFormSubmit = e => {
    e.preventDefault();

   if(this.state.name)

      axios.post(`${API_PATH}`, {
            name: this.state.name,
            company: this.state.company,
            email:this.state.email,
            phone:this.state.phone,
            message:this.state.message,
            rgpdChecked:this.state.rgpdChecked
          })
          .then(result => {
            this.setState({
              mailSent: result.data.sent
            })

          })
          .catch(error => this.setState({ error: error.message }));
  };

  render() {

    if(this.state.mailSent===false){
      return (

        <footer className={this.state.styleName}>



        <div className='footerContent'>
          {this.state.title}
          <p>{this.state.content}</p>

          <div>
          <form action="#" className="ContactFormFooter" id="ContactFormFooter" >
            <div className="fieldset">

            <input type="text" id="name" name="name" placeholder="Votre nom et prénom (obligatoire)"
              value={this.state.name}
              onChange={(event) => this.handleUserInput(event)}
              className={this.state.nameStyle}
              required
            />

            <input type=" text" id="company" name="company" placeholder="Votre société"
              value={this.state.company}
              onChange={(event) => this.handleUserInput(event)}
              className={this.state.companyStyle}
            />
            </div>
            <div className="fieldset">

            <input type="email" id="email" name="email" placeholder="Votre email (obligatoire)"
              value={this.state.email}
              onChange={(event) => this.handleUserInput(event)}
              className={this.state.emailStyle}
              required
            />
            <input type="tel" id="phone" name="phone" placeholder="Votre numéro de téléphone"
              value={this.state.phone}
              onChange={(event) => this.handleUserInput(event)}
              className={this.state.phoneStyle}
            />
            </div>


            <textarea id="message" name="message" placeholder="Votre message"
              value={this.state.message}
              className={this.state.messageStyle}
              onChange={(event) => this.handleUserInput(event)}
              required
            ></textarea>
            <div id="rgpd">
            <input type="checkbox" value="1" id="rgpdChecked" name="rgpdChecked" onChange={(event) => this.handleUserInput(event)}/>
            <label htmlFor="rgpdChecked" >J’ai lu et accepte la <a href="/politique-de-confidentialite" title="Politique de confidentialité" target="_blank">politique de confidentialité</a> de ce site</label>
            </div>
            <FormErrors formErrors={this.state.formErrors} />
            <input type="submit" onClick={e => this.handleFormSubmit(e)} value="Envoyer votre message"  disabled={!this.state.formValid}/>
            <div>
                   {this.state.mailSent  &&
                     <div className="sucsess">Nous vous remercions pour votre message</div>
                   }
                   {this.state.error  &&
                     <div className="error">Un problème est survenu. Veuillez recommencer</div>
                   }
                 </div>
          </form >
          </div>
        </div>
        </footer>
      );
    }
    else {
        return (
          <footer className={this.state.style}>
          <div className='footerContent'>
             <h4>Votre message a bien été envoyé !</h4>
            <p>Nous vous recontacterons sous 48h.<br/>
             À bientôt</p>
          </div>
          </footer>
        );
    }
  }

}
export default ContactForm;
